import * as singleSpa from 'single-spa';
import { prefix } from './prefix';
import { createApplication } from './createApplication';

const routes = (window as any).APP_ROUTES;
const rootTag = (window as any).APP_ROOT_TAG;

for (const { appName, packageName, pathnames } of routes) {
    singleSpa.registerApplication(appName, createApplication(packageName), (location: Location) => {
        if (location.pathname === '/') {
            return appName === rootTag;
        }

        return prefix(location, ...pathnames);
    });
}

singleSpa.start();