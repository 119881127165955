import * as singleSpa from 'single-spa';

const loadingScreen = document.getElementById('volvo-chaz-mypages-bootstrap---loading-screen');

export interface IApplicationLifecycle<T = {}> {
    bootstrap: ApplicationLifecycleMethod<T> | ApplicationLifecycleMethod<T>[];
    mount: ApplicationLifecycleMethod<T> | ApplicationLifecycleMethod<T>[];
    unmount: ApplicationLifecycleMethod<T> | ApplicationLifecycleMethod<T>[];
}

export type ApplicationLifecycleMethod<T = {}> = (props: T & singleSpa.AppProps) => Promise<void>;

export const createHook = <T = {}>(packageName: string, hook: 'bootstrap' | 'mount' | 'unmount') => {
    const SystemJS = (window as any).System as any;

    return async (props: T & singleSpa.AppProps) => {
        let showLoadScreen = false;

        const packageUrl = await SystemJS.resolve(packageName);

        if (!SystemJS.has(packageUrl)) {
            showLoadScreen = true;

            loadingScreen!.classList.add('active');
        }
        return SystemJS.import(packageName).then((lifecycles: IApplicationLifecycle<T>) => {

            const lifecycle = lifecycles[hook];

            if (Array.isArray(lifecycle)) {
                return lifecycle.reduce((chain, signal) => {
                    return chain.then(() => signal(props));
                }, Promise.resolve());
            } else {
                return lifecycle(props);
            }
        }).finally(() => {
            if (showLoadScreen) {
                loadingScreen!.classList.remove('active');
            }
        });
    }
}